.navbar-position-fixed{
    position: fixed;
    z-index: 1111;
    width: 100%;
    box-shadow:-1px 4px 20px 0px #4b4b4b
}

.navbar-top{
    display: flex;
    justify-content: space-between;

    align-items: center;
    position: relative;
    width: 100%;
    background-color: white;

}



.n-items{
    list-style-type: none;
    margin: 5px 10px;
    padding: 0px 5px;
    line-height: 3;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.n-items:hover{
    cursor: pointer;
    background-color: rgb(233, 233, 233);
    border-radius: 5px;
    color: rgb(189, 0, 0);
    letter-spacing: 1px;
}


/* Mega Menu */

.inner-menu{
    position: absolute;
    z-index: 1111;
    width: 98%;
    left: 50%;
    transform: translateX(-50%);

    /* opacity: 1;
    visibility: visible; */

opacity: 0;  
visibility: hidden;

    transition: all 0.3s ease-in-out;
}

.hover-item:hover .inner-menu{
opacity: 1;
visibility: visible;
}

.inner-menu{
    padding: 15px;
    background-color: white;
border-radius: 0px 0px 10px 10px;
}

.inner-menu h4{
    color: black;
    letter-spacing: 0px;
    margin-bottom: 40px;
}

.inner-menu-items{
    list-style-type: none;
}

.menu-title{
color: rgb(90, 0, 0);
letter-spacing: 0px;
line-height: 1;
margin: 0px 0px 20px 0px;
}

.menu-inner-items{
    line-height: 1;
    letter-spacing: 0px;
    color: black;
    transform: translateX(0px);
    transition: all 0.3s ease-in-out;
    font-weight:450 ;
    font-size: 12px;
}

.menu-inner-items:hover{
    /* line-height: 0.8; */
        /* letter-spacing: 1px; */
    color: rgb(189, 0, 0);
    transform: translateX(5px);
    }


    .navs{
        --bs-nav-link-padding-x: 1rem;
        --bs-nav-link-padding-y: 0.5rem;
        --bs-nav-link-font-weight: ;
        --bs-nav-link-color: var(--bs-link-color);
        --bs-nav-link-hover-color: var(--bs-link-hover-color);
        --bs-nav-link-disabled-color: var(--bs-secondary-color);

        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        width: 100%;
        justify-content: center;
        overflow: auto;
    }


    @media only screen and (max-width:600px){
        .navs{
            display: flex;
        flex-wrap: unset;
        padding-left: 100%;
        }

        .nav-icons{
            font-size: 30px;
            margin: 0px 5px;
            padding: 0px 5px;
        color: black;
        transition: all 0.3s ease-in-out;
    
        font-weight: 500;
        }

        .only-small{
display: inline-block;
            
        }

        .dash-nav{
            position: fixed;
            background-color: white;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        padding: 10px ;
        border-bottom: 1px solid rgb(212, 212, 212);
        }

        .margin-0-lineHeight{
            margin: 0px 0px;
            line-height: 3;
            font-size: 12px;
        }

        .n{
            display: flex;
            margin: 10px 0px;
            padding: 0px 0px;
            }

        .mobile-screen-noneed{
            display: none;

        }

        .navbar-top{
            padding: 5px 2%;
            }
        
    }
    
    @media only screen and (min-width:768px){
        .navs{
            display: flex;
        flex-wrap: unset;
        padding-left: 0%;
        }

        .dash-nav{
            position: fixed;
            background-color: white;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        padding: 10px ;
        border-bottom: 1px solid rgb(212, 212, 212);
        }

        .n{
            display: flex;
            margin: 10px 0px;
            padding: 0px 0px;
            }

            .nav-icons{
                font-size: 35px;
                margin: 0px 5px;
                padding: 0px 5px;
            color: black;
            transition: all 0.3s ease-in-out;
        
            font-weight: 500;
            }

            .margin-0-lineHeight{
                margin: 0px 0px;
                line-height: 3;
                font-size: 15px;
            }

        .only-small{
display: inline-block;
            
        }


        .navbar-top{
            padding: 5px 8%;
            }

        .mobile-screen-noneed{
            display: none;

        }

        .nav-icons{
            font-size: 35px;
            margin: 0px 5px;
            padding: 0px 5px;
        color: black;
        transition: all 0.3s ease-in-out;
    
        font-weight: 500;
        }
        
    }
    
    @media only screen and (min-width:998px){
        .navs{
            display: flex;
        flex-wrap: wrap;
        padding-left: 0%;
        }

        .margin-0-lineHeight{
            margin: 0px 0px;
            line-height: 3;
            font-size: 15px;
        }

        .only-small{
display: none;
            
        }


        .dash-nav{
            position: fixed;
            background-color: white;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        padding: 10px 30px;
        border-bottom: 1px solid rgb(212, 212, 212);
        }

        .navbar-top{
            padding: 5px 8%;
            }

        .n{
            display: flex;
            margin: 0px 0px;
            padding: 0px 0px;
            }

        .mobile-screen-noneed{
            display: flex;

        }

        .nav-icons{
            font-size: 35px;
            margin: 0px 5px;
            padding: 0px 5px;
        color: black;
        transition: all 0.3s ease-in-out;
    
        font-weight: 500;
        }

        .navbar-top{
            padding: 5px 8%;
            }
        
    }
    
    @media only screen and (min-width:1400px){
        .navs{
            display: flex;
        flex-wrap: wrap;
        padding-left: 0%;
        }

.navbar-top{
padding: 5px 8%;
}

        .only-small{
display: none;
        }

        .nav-icons{
            font-size: 35px;
            margin: 0px 5px;
            padding: 0px 5px;
        color: black;
        transition: all 0.3s ease-in-out;
    
        font-weight: 500;
        }


        .margin-0-lineHeight{
            margin: 0px 0px;
            line-height: 3;
            font-size: 15px;
        }

        .n{
            display: flex;
            margin: 0px 0px;
            padding: 0px 0px;
            }

        .mobile-screen-noneed{
            display: flex;
        }
        
    }


    .nav-link{
width: max-content;
    }


    
    .nav-icons:hover{
        color: white;
        cursor: pointer;
        border-radius: 50px;
    background-color: #bd0000;
    }



    /* Mobile Navbar */

    @media only screen and (max-width:600px){
.mobile-navbar{

    display: block;

}



.logo_icon{
    height: auto;
    width: 145px;
}
    }


        @media only screen and (min-width:768px){
.mobile-navbar{
    display: none;
}

.logo_icon{
    height: auto;
    width: 180px;

}


        }


        @media only screen and (min-width:1200px){
.mobile-navbar{
    display: none;
}

.logo_icon{
    height: auto;
    width: 160px;
}
        }

.mobile-navbar{
    position: fixed;
    width: 0%;
    height: 100vh;
    overflow: auto;
    background-color: rgb(251 251 251);
    top: 9%;
    left: -100%;
    z-index: 1111;
        transition: all 0.8s ease-in-out;
 }

 .mobile-nav-all_product{
    background-color: rgb(251 251 251);
    position: fixed;
    width: 100%;
    top: 13%;
    padding-top: 50px;
    padding-bottom: 25%;
    height: 100vh;
    left: -100%;
    overflow: auto;
    transition: all 0.3s ease-in-out;
    z-index: 11111;


}



 .openmenu{
    left: 0%;
    width: 100%;
    transition: all 0.3s ease-in-out;
    z-index: 11111;
 }



.mobile-navbarcontent{
padding: 10% 2% 0% 5%;
}

.mob-nav-items{
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0px 5px 0px;
    padding: 15px 10px;
}


.close_icon{
    position: absolute;
    top: 2%;
    right: 10%;
    font-size: 30px;
}


.submenu-series{
    background-color: #ffecec;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 13%;
    left: 0%;
}

.margin-0-lineHeight{
color: black;
}

.footerIcons{
    font-size: 20px
}


.heading-banner{
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-10%, -50%);
    color: white;
}

.footer{
    background-color: white;
    position: relative;
    z-index: 0;
    border-top: 5px solid rgb(223 223 223);
    border-radius: 10px 10px 0px 0px;
}


.hover-item-single .inner-menu-single{
    position: absolute;
opacity: 0;
}

.hover-item-single:hover.inner-menu-single{
    opacity: 1;

}



.logo img{
    width: 140px;
    height: auto;
}


.dash-nav{
z-index: 111111;
}

.mat-icons{
    font-size: 22px;
    margin-bottom: 1px;
}

.side-menu{
    position: fixed;
    height: 100vh;
    width: 200px;
    padding-top: 100px;

    background-color: white;
    box-shadow: 5px 0px 20px 0px #ececec;
}

.side-menu-items{
list-style-type: none;
padding: 0px;
}

.side-menu-items li{
    font-weight: 500;
    font-size: 15px;
    background:white;
    padding: 8px 0px 8px 15px;
    margin: 15px 0px;
    transition: all 0.3s ease-in-out;

}

.side-menu-items li:hover{
    background:rgb(236, 236, 236);
}



.inner-cat-items li{
    padding: 4px 0px 4px 10px;
    margin: 1px 0px;
    background:rgb(236, 236, 236);

}

.inner-cat-items li{
    padding: 8px 0px 8px 10px;
    margin: 1px 0px;
    background:transparent;

}


.drop-icon{

    transition: all 0.3s ease-in-out;
}

.rotate{
    transform: rotate(180deg);

}
.inner-cat-items{
    list-style-type: none;
    padding: 0px;
    background:transparent;
    transition: all 0.2s ease-in-out;
    height: 0px;
    visibility: hidden;
    opacity: 0;
    width: 100%;
}

.open{
    visibility: visible;
    height: 80px;
    opacity: 1;
}

.body-center{
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.side-dropmenu{
    cursor: pointer;
}