@media only screen and (max-width: 600px){
    .nav-item-links{
        display: none;
    }
    .only-mobile{
        display: block;
    
    }
.nav-strap{
    padding: 13px 10px;
    justify-content: space-between;
    top: 0px;

}

.top-header{
    padding: 10px 10px;
display: none;
}

}

@media only screen and (min-width: 998px){
.nav-item-links{
    display: block;
}
.only-mobile{
    display: none;
    
}
.nav-strap{
    padding: 0px 100px;
    justify-content: start;
    top: 45px;
}

.top-header{
    padding: 10px 100px;
    display: flex;

}


}

@media only screen and (min-width: 1400px){
.nav-item-links{
    display: block;
}
.only-mobile{
    display: none;


}
.nav-strap{
    padding: 0px 100px;
    justify-content: start;
    top: 35px;
}

.top-header{
    padding: 10px 100px;
display: flex;
}
}


.nav-strap{
position: relative;
left: 0px;
width: 100%;
top: 0px;
display: flex;
align-items: center;
justify-content: space-between;
z-index: 11111;
background-color: white;
box-shadow:0px 20px 20px 0px #00000024;
}

.navlogo img{
width: 120px;
height: auto;
margin: 0px 0px 0px 0px;
}


.nav-item-links .link-item{
    display: flex;
list-style-type: none;
padding: 0px;
margin: 0px;
}

.nav-item-links .link-item .item-dropdown-hov{
margin: 0px 5px;
padding: 25px 10px;
font-weight: 500;
cursor: pointer;
transition: all 0.3s ease-in-out;
color: black;
}

.nav-item-links .link-item .item-dropdown-hov:hover{
color: gray;
}


.item-dropdown-hov{
position: relative;
}
.item-dropdown-hov .drop-hov{
    position: absolute;
    /* opacity: 1;
    visibility: visible; */
    visibility: hidden; 
    opacity: 0;
    padding: 0px;
    background-color: white;
    box-shadow: 3px 4px 12px 0px #0000004d;
    width: max-content;
    list-style-type:none;
    top: 70px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.item-dropdown-hov:hover .drop-hov{
    opacity: 1;
    visibility: visible;

}

.item-dropdown-hov .drop-hov .drop-link{
    display: block;
    padding: 10px 5px;
transition: all 0.3s ease-in-out;
   
}

.item-dropdown-hov .drop-hov .drop-link:hover{
    background-color: rgb(249 249 249);
   
}

.item-dropdown-hov .drop-hov .drop-link2{
    display: block;
    padding: 10px 5px;
transition: all 0.3s ease-in-out;
   
}

.item-dropdown-hov .drop-hov .drop-link2:hover{
    background-color: rgb(249 249 249);
   
}

.item-dropdown-hov .drop-hov .drop-link3{
    display: block;
    padding: 10px 5px;
transition: all 0.3s ease-in-out;
   
}

.item-dropdown-hov .drop-hov .drop-link4{
    display: block;
    padding: 10px 5px;
transition: all 0.3s ease-in-out;
   
}

.item-dropdown-hov .drop-hov .drop-link3:hover{
    background-color: rgb(226, 226, 226);
   
}

.item-dropdown-hov .drop-hov .drop-link4:hover{
    background-color: rgb(226, 226, 226);
   
}

.drop-link5{
    position: relative;
    font-size: 13px;   
    padding-left: 5px;
}



.nolink-dec{
    color: black;
    text-decoration: none !important;
    transition: all 0.3s ease-in-out;
}

.nolink-dec:hover{
    color: gray;
    text-decoration: none !important;
}

.drop-link{
    position: relative;
font-size: 13px;
}

.drop-link2{
    position: relative;
font-size: 13px;
}

.drop-link3{
    position: relative;
font-size: 13px;
}

.drop-link4{
    position: relative;
font-size: 13px;
}

.hover-inner-links{
    position: absolute;
    list-style-type: none;
    left: 100%;
    top: 0px;
    background-color: white;
    box-shadow: 5px 0px 14px 0px #0000002b;
    padding: 0px;
width: max-content;
opacity: 0;
visibility: hidden;
height: 0px;
/* overflow-x: unset; 
overflow-y: scroll; */
border-radius: 5px;
z-index: 111;
transition: all 0.3s ease-in-out;
}

.hover-inner-links::-webkit-scrollbar {
    display: block;
    width: 4px;
}

.hover-inner-links::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius:2px ;


  }

.drop-link:hover .hover-inner-links{
    opacity: 1;
    visibility: visible;
    height: max-content;
}

.hover-inner-links2{
    position: absolute;
    list-style-type: none;
    left: 100%;
    top: 1px;
    background-color: white;
    box-shadow: 5px 0px 14px 0px #0000002b;
    padding: 0px;
width: max-content;
height: 0px;
opacity: 0;
visibility: hidden;
transition: all 0.3s ease-in-out;
border-radius: 5px;
/* overflow: hidden; */

}

.drop-link2:hover .hover-inner-links2{
    opacity: 1;
    visibility: visible;
    height: 180px;
}

.hover-inner-links3{
    position: absolute;
    list-style-type: none;
    right: -154px;
    top: 0px;
    background-color: white;
    box-shadow: 5px 0px 14px 0px #0000002b;
    padding: 0px;
width:max-content;
opacity: 0;
border-radius: 5px;
visibility: hidden;
height: 0px;
transition: all 0.3s ease-in-out;
}   



.drop-link3:hover .hover-inner-links3{
    opacity: 1;
    visibility: visible;
    height: 70px;
}

.drop-link4:hover .hover-inner-link4{
    opacity: 1;
    visibility: visible;
    height: 70px;
}

.inner-linkers{
    padding: 8px;
    font-size: 13px;
    position: relative;
}

.lastdrop{
opacity: 0;
visibility: hidden;
height: 0px;
transition: all 0.3s ease-in-out;
/* overflow-x: unset; 
overflow-y: scroll; */
list-style-type: none;
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 0px;
    left: 100%;
    width: max-content;
    background-color: white;
    box-shadow: 5px 0px 14px 0px #0000002b;

}

.lastdrop::-webkit-scrollbar {
    display: block;
    width: 4px;
}

.lastdrop::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius:2px ;

  }

.inner-linkers:hover .lastdrop{
visibility: visible;
    opacity:1;
    height:max-content;
    border-radius: 5px;
    /* box-shadow: inset 0px -20px 20px 0px #e7e7e7; */
}


.lastdrop-items{
padding:10px;
}

.lastdrop-items:hover{
    background-color: rgb(238, 238, 238);

}

.drop-linkers-items1{
    list-style-type: none;
    position: absolute;
    top: 0px;
    left: 100%;
    padding: 0px;
    height: 0px;
    background-color: white;
    box-shadow: 5px 0px 14px 0px #0000002b;
visibility: hidden;
transition: all 0.3s ease-in-out;
opacity: 0;
overflow: hidden;
border-radius: 5px;
}

.inner-linkers1{
    padding: 8px;
    font-size: 13px;
    position: relative;
}

.inner-linkers1:hover .drop-linkers-items1{
    visibility: visible;
        opacity:1;
        height:200px;
        width: max-content;
    }

    .drop-linkers-items2{
        list-style-type: none;
        position: absolute;
        top: 0px;
        left: 100%;
        padding: 0px;
        height: 0px;
        background-color: white;
        box-shadow: 5px 0px 14px 0px #0000002b;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    overflow: hidden;
    border-radius: 5px;
    }
    
    .inner-linkers2{
        padding: 8px 8px;
            margin: 0px 0px;
            font-size: 13px;
        position: relative;
    }

    .inner-linkerslast{
        padding: 8px 0px;
            margin: 0px 0px;
            font-size: 13px;
        position: relative;
    }
    
    .inner-linkers2:hover .drop-linkers-items2{
        visibility: visible;
            opacity:1;
            height:160px;
            width: max-content;
        }

        .drop-linkers-items3{
            list-style-type: none;
            position: absolute;
            top: 0px;
    border-radius: 5px;
            left: 100%;
            padding: 0px;
            height: 0px;
            background-color: white;
            box-shadow: 5px 0px 14px 0px #0000002b;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        opacity: 0;
        overflow: hidden;
        }
        
        .inner-linkers3{
            padding: 8px;
            font-size: 13px;
            position: relative;
        }
        
        .inner-linkers3:hover .drop-linkers-items3{
            visibility: visible;
                opacity:1;
                height:260px;
            width: max-content;
            }

            .drop-linkers-items4{
                list-style-type: none;
                position: absolute;
                top: 0px;
                left: 100%;
    border-radius: 5px;
                padding: 0px;
                height: 0px;
                background-color: white;
                box-shadow: 5px 0px 14px 0px #0000002b;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            overflow: hidden;
            }
            
            .inner-linkers4{
                padding: 8px;
                font-size: 13px;
                position: relative;
            }
            
            .inner-linkers4:hover .drop-linkers-items4{
                visibility: visible;
                    opacity:1;
                    height:120px;
            width: max-content;

                }

                .drop-linkers-items5{
                    list-style-type: none;
                    position: absolute;
                    top: 0px;
                    left: 100%;
                    padding: 0px;
                    height: 0px;
                    border-radius: 5px;
                    background-color: white;
                    box-shadow: 5px 0px 14px 0px #0000002b;
                visibility: hidden;
                transition: all 0.3s ease-in-out;
                opacity: 0;
                overflow: hidden;
                }
                
                .inner-linkers5{
                    padding: 8px;
                    font-size: 13px;
                    position: relative;
                }
                
                .inner-linkers5:hover .drop-linkers-items5{
                    visibility: visible;
                        opacity:1;
                        height:40px;
            width: max-content;

   }


   .drop-inner-link3-item1{
position: absolute;
width: max-content;
height: 0px;
right:-217px;
top:0px;
transition: all 0.3s ease-in-out;
visibility: hidden;
background-color:white;
box-shadow: 5px 0px 14px 0px #0000002b;
border-radius:5px ;
padding: 10px; 
list-style-type: none;  
}

   .drop-inner-link3-item2{
    position: absolute;
    width: max-content;
    height: 0px;
    right:-207px;
    top:0px;
transition: all 0.3s ease-in-out;
visibility: hidden;
background-color:white;
box-shadow: 5px 0px 14px 0px #0000002b;
border-radius:5px;
padding: 10px; 
list-style-type: none; 
}

.inner-linkers:hover .drop-inner-link3-item1{
visibility: visible;
    height: 90px;

}

.inner-linkers:hover .drop-inner-link3-item2{
visibility: visible;
    height: 240px;


}

.inner-linkers2{
    position: relative;
}

.ul-disable{
    list-style-type: none;
    padding: 0px !important;
}

.drop-items-poe{
    position: relative;
}

.drop-items-poe-links{
    position: absolute;
    opacity: 0;
    list-style-type: none;
    padding: 0px;
    width: max-content;
    top: 0px;
    right: -217px;
    height: 0px;
    background-color: white;
box-shadow: 5px 0px 14px 0px #0000002b;
transition: all 0.3s ease-in-out;
border-radius: 5px;
}

.drop-items-poe:hover .drop-items-poe-links{
    opacity: 1;
    height: 180px;
}

.arrow-icon{
    transform: rotate(0deg);
    transition: all 0.3s ease-in-out;
}

.inner-linkers:hover .arrow-icon{
    transform: rotate(90deg);
}

.inner-linkers:hover{
    background-color:rgb(231, 231, 231) ;
}

.top-header{
   
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
    position: fixed;
    width: 100%;
    z-index: 111;
    background-color: #f3f3f3;
}

.top-header p{
margin: 0px;
font-size: 12px;
font-weight: 500;
color: gray;    
letter-spacing: 1px;

}

.top-header a{
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 0px;
    color: gray;  
    transition: all 0.3s ease-in-out;

}

.top-header a:hover{
color: black;
}

.drop-inner-link4{
    position: absolute;
    width: max-content;
    list-style-type: none;
    padding: 0px;
    top: 0px;
    background-color: white;
    right: -224px;
    visibility: hidden;
    height: 0px;
    border-radius: 5px;
box-shadow: 5px 0px 14px 0px #0000002b;
    transition: all 0.3s ease-in-out;
    opacity:0 ;
}

.drop-link4:hover .drop-inner-link4{
    visibility: visible;
    height: 240px;
    opacity:1 ;
}

/* ---------------- */
.tele-inner-linkers1{
        padding: 8px;
        font-size: 13px;
        position: relative;
}

.tele-inner-linkers1:hover .tele-link1{
    height: 200px;
    visibility: visible;
    opacity:1 ;

}

.tele-link1{
    position: absolute;
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    top: 0px;
    right: -153px;
    opacity:0 ;
    background-color: white;
    border-radius: 5px;
    height: 0px;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
box-shadow: 5px 0px 14px 0px #0000002b;
}

.tele-inner-linke1{
    padding: 10px;
}

.tele-inner-linkers2{

    padding: 8px;
    font-size: 13px;
    position: relative;
}

.tele-inner-linkers2:hover .tele-link2{
    height: 275px;
    visibility: visible;
    opacity:1;
}

.tele-link2{
    position: absolute;
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    top: 0px;
    right: -108px;
    background-color: white;
    border-radius: 5px;
    height: 0px;
    visibility: hidden;
    opacity:0 ;
    transition: all 0.3s ease-in-out;
box-shadow: 5px 0px 14px 0px #0000002b;
}

.tele-inner-linke2{
    padding: 10px;
}

.tele-drop1, .tele-drop2, .tele-drop3, .tele-drop4, .tele-drop5{
    position: relative;
}


.tele-sublinkers1{
    position: absolute;
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    top: 0px;
    right: -90px;
    background-color: white;
    border-radius: 5px;
    opacity:0 ;
    height: 0px;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
box-shadow: 5px 0px 14px 0px #0000002b;
}

.tele-drop1:hover .tele-sublinkers1{
    height: 76px;
    visibility: visible;
    opacity:1;

}

.tele-sublinkers2{
    position: absolute;
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    top: 0px;
    opacity:0 ;
    right: -95px;
    background-color: white;
    border-radius: 5px;
    height: 0px;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
box-shadow: 5px 0px 14px 0px #0000002b;
}

.tele-drop2:hover .tele-sublinkers2{
    height: 116px;
    visibility: visible;
    opacity:1 ;

}

.tele-sublinkers3{
    position: absolute;
    padding: 0px;
    list-style-type: none;
    height: 0px;
    margin: 0px;
    top: 0px;
    right: -109px;
    background-color: white;
    border-radius: 5px;
    opacity:0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
box-shadow: 5px 0px 14px 0px #0000002b;
}

.tele-drop3:hover .tele-sublinkers3{
    height: 237px;
    visibility: visible;
    opacity:1 ;

}

.tele-sublinkers4{
    position: absolute;
    padding: 0px;
    height: 0px;
    opacity:0 ;
    list-style-type: none;
    margin: 0px;
    top: 0px;
    right: -93px;
    background-color: white;
    border-radius: 5px;
    height: 0px;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
box-shadow: 5px 0px 14px 0px #0000002b;
}

.tele-drop4:hover .tele-sublinkers4{
    height: 195px;
    visibility: visible;
    opacity:1 ;

}

.tele-sublinkers5{
    position: absolute;
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    top: 0px;
    opacity:0 ;
    right: -102px;
    background-color: white;
    border-radius: 5px;
    height: 0px;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
box-shadow: 5px 0px 14px 0px #0000002b;
}

.tele-drop5:hover .tele-sublinkers5{
    height: 240px;
    opacity:1 ;
    visibility: visible;

}

.list-item-tele li{
    padding: 10px;
}

.indus-link1{
    position: relative;
}
.indus-inner-link1{
    list-style-type: none;
    position: absolute;
    top: 0px;
    right: -126px;
    height: 0px;
    background-color: white;
    padding: 0px;
    opacity:0 ;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 0px 14px 0px #0000002b;    
    visibility: hidden;
}

.indus-link1:hover .indus-inner-link1{
    height: 160px;
    visibility: visible;
    opacity:1;
}

.indus-link2{
    position: relative;
}
.indus-inner-link2{
    list-style-type: none;
    position: absolute;
    top: 0px;
    right: -120px;
    opacity:0 ;
    height: 0px;
    background-color: white;
    padding: 0px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 0px 14px 0px #0000002b;    
    visibility: hidden;
}

.indus-link2:hover .indus-inner-link2{
    height: 119px;
    visibility: visible;
    opacity:1 ;

}

.indus-link3{
    position: relative;
}
.indus-inner-link3{
    list-style-type: none;
    position: absolute;
    top: 0px;
    right: -200px;
    height: 0px;
    opacity:0 ;
    background-color: white;
    padding: 0px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 0px 14px 0px #0000002b;    
    visibility: hidden;
}

.indus-link3:hover .indus-inner-link3{
    height: 81px;
    visibility: visible;
    opacity:1 ;

}

.indus-link4{
    position: relative;
}
.indus-inner-link4{
    list-style-type: none;
    position: absolute;
    top: 0px;
    opacity:0 ;
    right: -160px;
    height: 0px;
    background-color: white;
    padding: 0px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 0px 14px 0px #0000002b;    
    visibility: hidden;
}

.indus-link4:hover .indus-inner-link4{
    height: 925px;
    visibility: visible;
    opacity:1 ;

}

.indus-link5{
    position: relative;
}
.indus-inner-link5{
    list-style-type: none;
    position: absolute;
    top: 0px;
    right: -147px;
    height: 0px;
    opacity:0;
    background-color: white;
    padding: 0px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 0px 14px 0px #0000002b;    
    visibility: hidden;
}

.indus-link5:hover .indus-inner-link5{
    height: 240px;
    visibility: visible;
    opacity:1 ;

}

.indus-link6{
    position: relative;
}
.indus-inner-link6{
    list-style-type: none;
    position: absolute;
    opacity:0 ;
    top: 0px;
    right: -158px;
    height: 0px;
    background-color: white;
    padding: 0px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    box-shadow: 5px 0px 14px 0px #0000002b;    
    visibility: hidden;
}

.indus-link6:hover .indus-inner-link6{
    height: 690px;
    visibility: visible;
    opacity:1 ;

}


.navlogo h5, .navlogo h3, .navlogo h4{
    margin: 0px 60px 0px 0px;
    color: rgb(55 93 147);
    letter-spacing: 1px;
}


/* ----------------- */


/* DEV CSS Start */

.linkdecor-disable{
    text-decoration: none !important;
    }
    
    nav{
      display: flex;
      width: 100%;
      position: relative;
      background-color: #ffffff;
      align-items: center;
    }
    
    
    nav .section-1{
    
    }
    
    nav .section-1 h5{
    margin: 0px;
    padding-right: 80px;
    }
    
    
    nav .section-1{
      
    }
    
    
    
    .items-list{
    
        padding: 10px 5px;
        font-weight: 500;
        color: black;
    }
    .dropdown .drop-item li{
      padding: 8px 5px; 
    color: gray; 
    }
    
     /* Main Dropdown Start */
    
    
    .dropdown{
    position: relative;
    }
    
    .dropdown:hover .drop-item{
      opacity: 1;
    visibility: visible;
    transform: none;
    }
    
    
    
    
    @media only screen and (max-width: 600px){
     .drop-item{
      position: absolute;
      list-style: none;
      padding: 0px;
      margin: 0px;
      width: 104%;
      background-color: #ffffff;
      padding: 5px 0px;
      border-radius: 0px 0px 5px 5px;
      top: -84px;
      left: -4px;
      height: 91vh;
      opacity: 0;
    visibility: hidden;
      transition: all 0.2s ease-in-out;
      transform-origin:top ;
      transform: scaleY(0);
    }
    
    }
    
    @media only screen and (min-width: 998px){
    .dropdown .drop-item{
      position: absolute;
      list-style: none;
      padding: 0px;
      margin: 0px;
      width: max-content;
      background-color: #ffffff;
      padding: 5px 0px;
      border-radius: 0px 0px 5px 5px;
      /* top: 43px; */
      top: 72px;
      left: 0px;
      opacity: 0;
    visibility: hidden;
      transition: all 0.2s ease-in-out;
      transform-origin:top ;
      transform: scaleY(0);
    }
    }
    
     /* Main Dropdown End */
    
    
    /* first Dropdown Start */
    .item-drop{
      position: relative;
      background-color: #ffffff;
    
    }
    
    .item-drop:hover{
      background-color: rgb(249 249 249);
    
    }
    
    .item-drop:hover .sub-dropdown{
      opacity: 1;
    visibility: visible;
    transform: none;
    box-shadow: -2px 3px 10px 0px #00000017;
    }
    
    
    
    @media only screen and (max-width: 600px){
    .sub-dropdown{
      position: absolute;
      width: max-content;
      top: 0px;
      list-style: none;
      left: 100%;
      padding: 0px;
      opacity: 0;
      visibility: hidden;
      margin: 0px;
      background-color: white;
      border-radius: 0px 0px 5px 5px;
      transition: all 0.2s ease-in-out;
      transform-origin:top ;
      transform: scaleY(0);
    }
    }
    
    @media only screen and (min-width: 998px){
    .sub-dropdown{
      position: absolute;
      width: max-content;
      top: 0px;
      list-style: none;
      left: 100%;
      padding: 0px;
      opacity: 0;
      visibility: hidden;
      margin: 0px;
      background-color: white;
      border-radius: 0px 0px 5px 5px;
      transition: all 0.2s ease-in-out;
      transform-origin:top ;
      transform: scaleY(0);
    }
    }
    
    /* first Dropdown End */
    
    
    /* second Dropdown Start */
    
    .inner-menus:hover{
    background-color: rgb(249 249 249);
    transition: all 0.2s ease-in-out;
    }
    
    .sub-drops{
      position: relative;
      background-color: white;
    
    }
    
    .sub-drops:hover{
      background-color: rgb(249 249 249);
    
    }
    
    .sub-drops:hover .inner-item{
      opacity: 1;
    visibility: visible;
    transform: none;
    box-shadow: -2px 3px 10px 0px #00000017;

    }
    
    .inner-item{
      position: absolute;
      width: max-content;
      top: 0px;
      list-style: none;
      left: 100%;
      padding: 0px;
      opacity: 0;
      visibility: hidden;
      margin: 0px;
      background-color: white;
      border-radius: 0px 0px 5px 5px;
      transform-origin: top;
      transform: scaleY(0);
      transition: all 0.2s ease-in-out;
      z-index: 1;
    }
    
    @media only screen and (max-width: 600px){
    
    }
    
    @media only screen and (min-width: 998px){
    
    }
    /* second Dropdown End */
    
    /* Third Dropdown Start */
    
    .sub-item-list:hover{
      background-color: rgb(249 249 249);
      transition: all 0.2s ease-in-out;
      }
      
      .inner-menus{
        position: relative;
        background-color: white;
      
      }
      
      .inner-menus:hover{
        background-color: rgb(249 249 249);
      
      }
      
      .inner-menus:hover .inner-menu-sub-items{
        opacity: 1;
      visibility: visible;
      transform: none;
    box-shadow: -2px 3px 10px 0px #00000017;
      }
      
      .inner-menu-sub-items{
        position: absolute;
        width: max-content;
        top: 0px;
        list-style: none;
        left: 100%;
        padding: 0px;
        opacity: 0;
        visibility: hidden;
        margin: 0px;
        background-color: white;
        border-radius: 0px 0px 5px 5px;
        transform-origin: top;
        transform: scaleY(0);
        transition: all 0.2s ease-in-out;
      z-index: 11;
      }
    
      @media only screen and (max-width: 600px){
    
    }
    
    @media only screen and (min-width: 998px){
    
    }
      /* Third Dropdown End */

     /* Forth Dropdown Start */
    
   
        
        .sub-item-list{
          position: relative;
          background-color: white;
          color: gray;
        
        }
        
        .sub-item-list:hover{
          background-color: rgb(249 249 249);
        
        }
        
        .sub-item-list:hover .forth-inner-menu-sub-items{
          opacity: 1;
        visibility: visible;
        transform: none;
        box-shadow: -2px 3px 10px 0px #00000017;
        }

        .forth-sub-item-list:hover{
            background-color: rgb(249 249 249);
            transition: all 0.2s ease-in-out;
        
            }

        .forth-inner-menu-sub-items{
          position: absolute;
          width: max-content;
          top: 0px;
          list-style: none;
          left: 100%;
          padding: 0px;
          opacity: 0;
          visibility: hidden;
          margin: 0px;
          background-color: white;
          border-radius: 0px 0px 5px 5px;
          transform-origin: top;
          transform: scaleY(0);
          transition: all 0.2s ease-in-out;
      z-index: 111;
        }
        
      
        /* Forth Dropdown End */
    
    @media only screen and (max-width: 600px){
      .list{
        display: list-item;
        position: absolute;
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        width: 100%;
        left: 0px;
        top: 100%;
        height: 0vh;
        background-color: #e4eeff;
        overflow: hidden;
        transform-origin: top;
        transform: scaleY(0);
        transition: all 0.2s ease-in-out;
      }
    .menubar-mobile .icon{
      font-size: 25px;
      display: block;
    
        }
    
        nav{
            padding: 10px 10px;
            justify-content: space-between;
        }
    
        .items-list{
          margin: 20px 5px;
        }
        
    .openmobilemenu{
      height: 100vh;
      transform: none;
      }
    
      .close-icons{
        font-size: 26px;
        display:block;
      }
    
    
    
    }
    
    
    
    @media only screen and (min-width: 998px){
      .list{
      display: flex;
      position: relative;
        margin: 0px;
        padding: 0px;
        list-style-type: none;
    }
    
    .menubar-mobile .icon{
      font-size: 25px;
      display: none;
        }
    
        nav{
            padding: 0px 90px;
            justify-content: start;
        }
    
        .items-list{
          margin: 0px 5px;
        }
    
        .close-icons{
          font-size: 26px;
          display:none;
        }
    
    }
    
    /*Dev CSS End  */


.font-size-sm{
    font-size: 14px !important;
}

.font-bold-4{
    font-weight: 400;
}