
@media only screen and (max-width:600px){
.product-contain{
    padding: 40% 5% 5px 5%;
}

.product-img{
width: 100%;
margin-bottom: 20px;
}

.sectionImg2_5{
width: 100%;
height: auto;
}

.sectionImg2_2{
    height: 200px;
    width: 100%;
}

.mainproduct-img{
    width: 100%;
    height: auto;
    }

    .section-fixed{
        position: relative;
        width: 100%;
        padding-top:5% ;
    }


    .product-content h1{

font-size: 20px;
    }


    .product-overview-6{
        width: 100%;
    }

}

@media only screen and (min-width:768px){
.product-contain{
    padding: 20% 5% 5px 5%;

}

.product-img{
width: 100%;
margin-bottom: 20px;
}

.sectionImg2_5{
    width: 70%;
    height: auto;
    }

.sectionImg2_2{
    height: 200px;
    width: 80%;
}


.mainproduct-img{
    width: 100%;
    height: auto;
    }

    .section-fixed{
        position: relative;
        width: 100%;
        padding-top:5% ;
    }

    .product-content h1{
font-size: 25px;

    }

    .product-overview-6{
        width: 50%;
    }

}

@media only screen and (min-width:998px){
.product-contain{
    padding: 15% 5% 5px 5%;

}

.product-img{
width:30% ;
/* height: 100vh; */

}

.sectionImg2_5{
    width: 80%;
    height: auto;
    }

.sectionImg2_2{
    height: 200px;
    width: 50%;
}

.product-content{
    width: 70%;
    padding-left: 50px;

}

.product-overview p{
font-size: 15px;
}

.mainproduct-img{
    width: 100%;
    height: auto;
    }

    .section-fixed{
        position: sticky;
        width: 100%;
        padding-top:5% ;
        top: 30%;
    }

    .product-content h1{

font-size: 28px;
    }

    .product-overview-6{
        width: 50%;
    }
    

}

@media only screen and (min-width:1400px){
.product-contain{
padding: 10% 5% 5px 5%;
}

.product-img{
    width: 30%;
    /* height: 500px; */
}

.sectionImg2_5{
    width: 90%;
    height: auto;
    }

.product-content{
    width: 70%;
    padding-left: 50px;
}

.sectionImg2_2{
    height: 200px;
    width: 50%;
}

.mainproduct-img{
width: 100%;
height: auto;
}

.section-fixed{
    position: sticky;
    width: 100%;
    padding-top:5% ;
    top: 30%;
}

.product-content h1{
font-size: 40px;

}

.product-overview p{
font-size: 18px;
}

.product-overview-6{
    width: 50%;
}

}


.product-content{
    position: relative;

}

.product-img{
    position: relative;

}



.but-button{
width: 100%;
}

.product-btn{
    width: 100%;
    margin: 0px;
    line-height: 2;
    color: white;
    font-weight: 500;
}


.btn-cover{
    width: 100% !important;
}

.product-content span{
color: gray;
}




.light-box-img{
    height: 60px;
    width: 100%;
    border: 1px solid black;
    border-radius: 5px;
    padding: 2%;
}

.mainproduct-img{
padding: 5px;
border-radius: 5px;   
box-shadow: 0px 1px 8px 0px #dbdbdb;
}

.product-contain-col-two p{

}


/* --------New Product ----------*/

@media only screen and (max-width:600px){
.product-struct{
    padding:10% 2% 5% 2%;
}

.img-main{
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    
}

.img-main2{
    width: auto;
    height: 200px;
    margin-bottom: 20px;
    
}


.pricing-section{

width: 100%;
}

.sectionImg2_6{
    height: auto;
    width: 100%;
    
    }

.pricing-section p{
    margin: 0px ;
    line-height: 4;
    font-size: 10px;
    }

.banner-heading{
    position: absolute;
    left: 85%;
    color: black;
    top: 50%;
    transform: translate(-85%, -50%);
    font-weight: 500;
    margin: 0px;
    width: max-content;
    letter-spacing: 2px;
}

.lightbox-img{
     height: auto;
    width: 70px;
    margin: 0px 5px;
    padding: 10px;
    border-radius: 5px;

}

.lightbox-img2{
    width:50px;
}

.productImgLightBox{
    width: 100%;
}

.product-content{
    width: 100%;
}

.btn-buy_now{
background-color: #ff5416;
    width: 90%;
    text-align: center;
    color: white;
    text-decoration: none;
    line-height: 2.5;
}

.sectionImg2_1{
    height: 100px;
    width: auto;
}

.cart-btn{
    width:100%;
    margin-top: 40px;
}

.LightBox{
    position: relative;
    top: 0%;
    margin-bottom: 30px;
    }

.sectionImg2{
    height: 500px;
width: 100%;
}

.sectionImg3{
    height: 250px;
    width: 100%;
}
.sectionImg4{
    width: 300px;
    height: auto;
 }

}

@media only screen and (min-width:786px){
.product-struct{
    padding:20% 5% 0% 5%;
}

.banner-heading{
    position: absolute;
    left: 85%;
    color: black;
    top: 50%;
    transform: translate(-85%, -50%);
    font-weight: 500;
    margin: 0px;
    width: max-content;
    letter-spacing: 2px;
}

.sectionImg2_6{
    height: auto;
    width: 60%;
    
    }

.pricing-section p{
    margin: 0px ;
    line-height: 2;
    font-size: 15px;
    }


.pricing-section{

width: 80%;
}

.cart-btn{
    width:100%;
    margin-top: 40px;
}

.sectionImg2_1{
    height: 250px;
    width: auto;
}

.img-main{
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    
}

.img-main2{
    width: auto;
    height: 200px;
    margin-bottom: 20px;
    
}

.lightbox-img{
    height: 50px ;
    width:100px;
    margin: 0px 5px;
    padding: 10px;
    border-radius: 5px;
}

.lightbox-img2{
    width:50px;
}

.btn-buy_now{
background-color: #ff5416;
    width: 80%;
    text-align: center;
    color: white;
    text-decoration: none;
    line-height: 2.5;
}



.productImgLightBox{
    width: 100%;
}

.product-content{
    width: 100%;
}

.lightbox-img{
    height: 50px ;
    width:100px;
    margin: 0px 5px;
    padding: 10px;
    border-radius: 5px;
}

.cart-btn{
    width:100%;
    margin-top: 40px;
}

.LightBox{
    position: sticky;
    top: 20%;
    margin-bottom: 0px;

    }

    .sectionImg2{
        height: 700px;
        width: auto;
    }

    .sectionImg3{
        height: 400px;
        width: auto;
    }
    .sectionImg4{
        width: 400px;
        height: auto;
     }

}

@media only screen and (min-width:998px){
.product-struct{
    padding:8% 8% 0% 8% ;
}

.img-main{
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    
}

.img-main2{
    width: auto;
    height: 200px;
    margin-bottom: 20px;
    
}

.sectionImg2_6{
    height: auto;
    width: 60%;
    
    }

.banner-heading{
    position: absolute;
    color: black;
    left: 51%;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
    margin: 0px;
    width: max-content;
    letter-spacing: 2px;
}

.sectionImg2_1{
    height: 275px;
    width: auto;
}

.lightbox-img{
    height: 50px ;
    width:100px;
    margin: 0px 5px;
    padding: 10px;
    border-radius: 5px;
}

.lightbox-img2{
    width:50px;
}

.btn-buy_now{
background-color: #ff5416;
    width: 50%;
    text-align: center;
    color: white;
    text-decoration: none;
    line-height: 2.5;
}


.productImgLightBox{
    width: 40%;
}

.product-content{
    width: 60%;
}



.cart-btn{
    width:100%;
    margin-top: 40px;
}

.LightBox{
    position: sticky;
    top: 20%;
    margin-bottom: 0px;

    }

    .sectionImg2{
        height: 700px;
        width: auto;
    }

    .sectionImg3{
        height: 300px;
        width: auto;
    }
    .sectionImg4{
        width: 700px;
        height: auto;
     }

    .pricing-section{

width: 95%;
    }

    .pricing-section p{
        line-height: 3;
            font-size: 17px;
            font-weight: 400;
            margin: 0px;
        }

}

@media only screen and (min-width:1400px){
.product-struct{
    padding:15% 8% 0% 8% ;
}

.pricing-section{
width: 80%;

}


.LightBox{
    position: sticky;
    top: 20%;
    margin-bottom: 0px;

    }

    .pricing-section p{
        margin: 0px ;
        line-height: 1;
        }

    .sectionImg2_1{
        height: 375px;
        width: auto;
    }

    .banner-heading{
        position: absolute;
        left: 85%;
        color: black;
        top: 50%;
        transform: translate(-85%, -50%);
        font-weight: 500;
        margin: 0px;
        width: max-content;
        letter-spacing: 2px;
    }

.btn-buy_now{
background-color: #ff5416;
    width: 50%;
    text-align: center;
    color: white;
    text-decoration: none;
    line-height: 2.5;
}

.lightbox-img{
    height: 50px ;
    width:100px;
    margin: 0px 5px;
    padding: 10px;
    border-radius: 5px;
}

.lightbox-img2{
    width:50px;
}

.img-main{
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
    
}

.img-main2{
    width: auto;
    height: 300px;
    margin-bottom: 20px;
    
}



.productImgLightBox{
    width: 40%;
    /* height: 100vh; */
}

.product-content{
    width: 60%;
}

.pricing-section p{
line-height: 3;
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
}

.sectionImg2_6{
height: auto;
width: 60%;

}

.cart-btn{
    width:100%;
    margin-top: 40px;
}

.sectionImg2{
    height: 700px;
    width: auto;
}

.sectionImg3{
    height: 400px;
    width: auto;
}
.sectionImg4{
   width: 800px;
   height: auto;
}

}

/*--------------------- Swiper -------------------- */

.product-struct{
    display: flex;
    flex-wrap: wrap;
}


.pricing-section{
    background-color: #e3e3e3;
    padding: 0px 20px;
    border-radius: 10px;
}





.cart-btn-buy{
width: 100%;
text-align: center;
}

.cart-btn-cart{
  width: 100%;
  text-align: center;  
}


.LightBox{
    background-color: #ededed;
    padding: 20px;
    border-radius: 10px;
}

.img-main{
border-radius: 10px;
}


.lightbox-img{
background-color: white;
}

.listFeture{
    margin: 10px 15px;
    font-size: 13px;
    font-weight: 400;
}

.banner_img2{
    height: 100px;
  
}



.productImg3{
    width: 100%;
    height: 230px;
    transition: all 0.4s ease-in-out;

}

.product-dec{
    padding-top: 20px;
}

.product-dec h5{
    font-size: 13px;
    font-weight: 350;
}

.product-card:hover{
cursor: pointer;
}

.product-dec p{
font-weight: 500;
font-size: 13px;
}

.enquire-btn{
    position: absolute;
    top: 0%;
    right: 5%;
    background-color: #008ad2;
    transform: translate(-5%, -0%);
    color: white;
    font-size: 50px;
    padding: 5px;
    border-radius: 50px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.product-card:hover .enquire-btn{
    opacity: 1;
    transition: all 0.3s ease-in-out;
    top: 5%;
    transform: translate(-5%, -5%);
cursor: pointer;
}

.tab-content h2{
    text-align: center;
}
.tab-content h1{
    text-align: center;
}
h1{
    text-align: center;
}

.link-black{
     text-decoration: none;
     color:  black;
}


